import { NAME_FILTER, REGION_FILTER, COMMUNE_FILTER, CURRENT_PAGE } from '../constants/index';

export const offerFilters = (state = {nameFilter: undefined, regionFilter: undefined, communeFilter: [], currentPage: 0}, {type, payload}) => {
  switch (type) {
    case NAME_FILTER:
      return {
        ...state,
        nameFilter: payload
      }
    case REGION_FILTER:
      return {
        ...state,
        regionFilter: payload
      }
    case COMMUNE_FILTER:
      return {
        ...state,
        communeFilter: payload
      }
    case CURRENT_PAGE:
      return {
        ...state,
        currentPage: payload
      }
    default:
      return state;
  }
}
