import React from 'react'

const ImgChecked = () => {
  return (
    <div>
      <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtb
        G5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4
        PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDI4Ni4wNTQgMjg2LjA1NCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5
        kOm5ldyAwIDAgMjg2LjA1NCAyODYuMDU0OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgd2lkdGg9IjUxMiIgaGVpZ2h0PS
        I1MTIiIGNsYXNzPSIiPjxnPjxnPgoJPHBhdGggc3R5bGU9ImZpbGw6IzhDQjAwRSIgZD0iTTE0My4wMzEsMEM2NC4wM
        jcsMCwwLjAwNCw2NC4wNCwwLjAwNCwxNDMuMDI3YzAsNzguOTk2LDY0LjAzMSwxNDMuMDI3LDE0My4wMjcsMTQzLjAy
        NyAgIGM3OC45ODcsMCwxNDMuMDE4LTY0LjAzMSwxNDMuMDE4LTE0My4wMjdDMjg2LjA0OSw2NC4wNDksMjIyLjAxOCw
        wLDE0My4wMzEsMHogTTE0My4wMzEsMjU5LjIzNiAgIGMtNjQuMTgzLDAtMTE2LjIwOS01Mi4wMjYtMTE2LjIwOS0xMT
        YuMjA5Uzc4Ljg1NywyNi44MTgsMTQzLjAzMSwyNi44MThzMTE2LjIsNTIuMDI2LDExNi4yLDExNi4yMDkgICBTMjA3L
        jIwNiwyNTkuMjM2LDE0My4wMzEsMjU5LjIzNnogTTE5OS4yNDEsODIuMTg3Yy02LjA3OS0zLjYyOS0xMy44NDctMS40
        NzUtMTcuMzQyLDQuODI3bC00Ny45NTksODYuMTQ3bC0yNi43MS0zMi41MTIgICBjLTQuODM2LTUuNTY5LTExLjI2My0
        4LjQ1Ni0xNy4zMzMtNC44MjdjLTYuMDc5LDMuNjM4LTguNTkxLDEyLjM5LTQuNjU3LDE4LjAwNGwzNy4xNjksNDUuMj
        QxYzIuNzgsMy42MTEsNS45NTMsNS43NzUsOS4yNyw2LjM5MiAgIGwwLjAyNywwLjA1NGwwLjM0LDAuMDE4YzAuNzUxL
        DAuMTE2LDExLjk3OSwyLjE5LDE2LjgxNS02LjQ2M2w1NS4wNDgtOTguODc2QzIwNy40MDIsOTMuODc5LDIwNS4zMiw4
        NS44MjUsMTk5LjI0MSw4Mi4xODd6IiBkYXRhLW9yaWdpbmFsPSIjM0RCMzlFIiBjbGFzcz0iYWN0aXZlLXBhdGgiIGR
        hdGEtb2xkX2NvbG9yPSIjM0RCMzlFIj48L3BhdGg+CjwvZz48L2c+IDwvc3ZnPg==" width={150} height={150}
        className="tcn-checked"
      />
    </div>
  )
}


export default ImgChecked;
