import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { getOffers } from '../../../store/actions/offerlist';
import { setCurrentPage } from '../../../store/actions/offer_filters';
import { NavLink } from 'react-router-dom'
import PaginationBloque from './paginationBloque'

const url = process.env.REACT_APP_BACKEND_HOST + '/company/public_offers/';
const kc_api_key = process.env.REACT_APP_KC_API_KEY;
var config = { headers: {'Access-Control-Allow-Origin': '*'} };
axios.defaults.headers.common['Authorization'] = kc_api_key;

const mapStateToProps = state => {
  return {
    offersList: state.offersList,
    offerFilters: state.offerFilters
  };
}

class PanelIndexConnected extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      offers: [],
      redirect: false,
      offerId: ''
    }
  }

  getOffers(currentPage = null) {
    const params = {
      company_id: this.props.company,
      filter: true,
      current_page: currentPage != null ? currentPage : this.props.offerFilters.currentPage,
      filters: {
        name_cont: this.props.offerFilters.nameFilter,
        region_cont: this.props.offerFilters.regionFilter,
        communes: this.props.offerFilters.communeFilter,
      }
    }
    axios.get(url, {params}, config)
    .then(response => {
      this.props.dispatch(getOffers(response.data));
      this.setState({offers: this.props.offersList.offers});
    })
    .catch(error => { console.log(error.response) });
  }

  handleShowOffer = (id) => {
    console.log(id);
  }

  formatDescription = (description) => {
    description = description.replace(/<\/?[^>]+(>|$)/g, "").replace(/\s\s+/g, ' ').replace(/&nbsp;/g, " ");

    if ((!description) || (description.length < 420)) { return description }
    const new_text = description.substring(0, 420) + '...</p>'
    return new_text
  }

  pagesHandler(value) {
    this.props.dispatch(setCurrentPage(value.selected));
    this.getOffers(value.selected);
  }

  componentDidMount() {
    this.getOffers();
  }

  render() {
    const company = this.props.subdomain;
    const url = '/'+ company + '/';
    return (
      <>
        {this.props.offersList.offers ? this.props.offersList.offers.map((offer, index) => {
          return(
            <div className='row separating-line pb-2 mb-2 mx-0 border-bottom' key={index}>
              <div className='col-md-3 ps-0 d-flex'>
                <div className="name-offer">
                  <h3><NavLink to={{pathname: url + offer.id}} className="title-to-show">{offer.name}</NavLink></h3>

                  <p style={{color: 'rgba(124, 141, 176)', fontSize: '14px', marginTop: '35px', left: '0px'}} >{offer.commune + ', ' + offer.region}</p>

                  <p style={{color: 'rgba(124, 141, 176)', fontSize: '14px', marginTop: '-10px'}} >{offer.modality}</p>
                </div>
              </div>
              <div className='col-md-9 px-0'>
                <p className="index-date">{offer.created_at}</p>
                <div className="offer-description wordBreak">

                  <p style={{color: 'rgba(94, 94, 94, 1)', fontSize: '14px', textAlign: 'justify', lineHeight: '1.5', margin: '0px'}} dangerouslySetInnerHTML={{ __html: this.formatDescription(offer.description) }}></p>

                </div>
              </div>
            </div>
          )
        }) : null}
        {this.props.offersList.offers && this.props.offersList.offers.length === 0 ? 
          <p className="tcn-region-p">No se encontraron ofertas laborales</p> 
        : null}
        {this.props.offersList.offers ?
          <PaginationBloque
          pagesHandler={this.pagesHandler.bind(this)}
          currentPage={this.props.offerFilters.currentPage}
          pagesCount={this.props.offersList.pagesCount}
          resultsCount={this.props.offersList.resultsCount}
          />
        : null}
      </>
    );
  }
}

const PanelIndex = connect(mapStateToProps)(PanelIndexConnected);
export default PanelIndex;
