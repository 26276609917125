import React from 'react';
import { Breadcrumb } from 'react-bootstrap';

const BreadcrumbContainer = (props) => {
  return (
    <Breadcrumb>
      {!props.confidential && <Breadcrumb.Item style = {{color: 'rgba(0, 0, 0)', }} href={"/" + props.company}>Ofertas laborales de {props.company}</Breadcrumb.Item>}
      {props.confidential && <Breadcrumb.Item active style = {{color: 'rgba(94, 94, 94, 1)'}} >Ofertas laborales</Breadcrumb.Item>}
      <Breadcrumb.Item active style={{marginLeft: '2px', color: 'var(--haunter-purple)'}}>{props.offerCargo ? props.offerCargo : null}</Breadcrumb.Item>
    </Breadcrumb>
  )
}

export default BreadcrumbContainer;
