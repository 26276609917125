// Index offers
export const ADD_OFFER = 'ADD_OFFER';
export const GET_OFFERS = 'GET_OFFERS';

// Login-applicant
export const APPLICANT_SESSION = 'APPLICANT_SESSION';

export const IMAGES_COMPANY = 'IMAGES_COMPANY';

export const NOT_FOUND = 'NOT_FOUND';

// Filters
export const NAME_FILTER = 'NAME_FILTER';
export const REGION_FILTER = 'REGION_FILTER';
export const COMMUNE_FILTER = 'COMMUNE_FILTER';
export const CURRENT_PAGE = 'CURRENT_PAGE'
