import { APPLICANT_SESSION } from '../constants/index';

export const applicantSession = (state = {}, {type, applicant}) => {
  switch (type) {
    case APPLICANT_SESSION:
      return {
        applicant: applicant
      }
    default:
      return state;
  }
}