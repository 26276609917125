import React from 'react';
import Icon from '@mdi/react';

const Mensaje = (props) => {
  return (
    <div className={props.className}>
      { props.icon && (
        <Icon path={props.icon}
          size="30px"
          color="initial"
        />
      )}
      <span className={props.class}>{props.mensaje}</span>
    </div>
  )
}

export default Mensaje;