import React from 'react';
import OfferIndex from './views/offer_index/containers/offer_index';
import OfferShow from './views/offer_show/containers/offer_show';
import Layout from './views/layout/containers/layout';
import LayoutForShow from './views/layout/containers/layout_for_show';
import Footer from './views/layout/components/footer/footer';
import { Route, Switch } from 'react-router'

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route {...rest} render={props => (
    <>
      <Layout>
        <Component {...props} />
      </Layout>
      <Footer />
    </>
  )}/>
)

export const Routes = () => {
  return (
    <Switch>
      <AppRoute exact path="/" component={Home} layout={Layout} />
      <AppRoute exact path="/:company" component={OfferIndex}  layout={Layout}  />
      <AppRoute path="/offers/:offer" component={OfferShow}  layout={LayoutForShow} />
      <AppRoute path="/:company/:offerId" component={OfferShow}  layout={LayoutForShow} />
    </Switch>
  )
};

const Home = () => (
  <div className='container'>
    <h1>Mis Ofertas Laborales</h1>
  </div>
)

