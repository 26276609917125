import React from 'react';
import axios from 'axios';
import Modal from 'react-responsive-modal';
import { ValidateEmail } from '../../../assets/methods/validateEmail';
import Mensaje from '../../../common/messages/mensajeError';
import { mdiCheckCircle } from '@mdi/js';

const errorInputStyles = { borderColor: 'red', borderWidth: '2px' }

const modalStyle = {
  boxShadow: '0 5px 15px rgba(0, 0, 0, 0.4)',
  borderRadius: '10px',
  margin: '160px auto',
  position: 'relative',
  padding: '28px 18px',
  width: '410px',
}

export default class RecoverPasswordModal extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      email: '',
      errorEmail: false,
      errorMessage: '',
      emailError: false,
      success: false,
      loading: false,
    }
  }

  handleSendPassword() {
    this.setState({emailError: false, success: false})

    const email = this.state.email;

    if(!email.length || !ValidateEmail(email)) {
      this.setState({emailError: true, errorMessage: 'Debes ingresar un email válido'})
      return
    }

    this.setState({ loading: true })

    const kc_url = process.env.REACT_APP_KEYCLOUD_HOST;

    axios.post(kc_url + "/api/v2/applicant/forgot_password", {email: email})
    .then(() => {
        this.setState({ success: true, loading: false })
      })
      .catch(error => {
        if (error.response.status === 404) {
          this.setState({ emailError: true, errorMessage: 'Este email no se encuentra registrado', loading: false })
        }
        console.log(error.response)
      })
  }

  handleRecoveryMail(event) {
    this.setState({email: event.target.value})
  }

  handleKeyPress(target) {
    if (target.charCode == 13) {
      console.log('entro aca');
      this.props.sendPassword();
    }
  }

  onCloseRecovery() {
    this.setState({forgot: false, emailError: false, success: false, email: ''})
  }

  render() {
    return (
      <Modal
        open={this.props.open}
        onClose={this.props.onClose}
        styles={{modal: modalStyle}}
        closeIconSize={24}
      >
        <div className="vstack justify-contnet-center gap-3 text-center">
          <div>Ingresa tu email y te enviaremos tu contraseña</div>
          {/* Success message */}
          { this.state.success && 
            <Mensaje className="alert alert-success m-0" icon={mdiCheckCircle} mensaje="Se ha enviado un email con tu contraseña" /> 
          }
          <div className="form-floating">
            <input
              onKeyUp={this.handleKeyPress.bind(this)} 
              type="email" className="form-control" id="emailFloatingInput" placeholder="correo@example.com"
              style={this.state.emailError ? errorInputStyles : {}} 
              onChange={this.handleRecoveryMail.bind(this)}
              autoFocus
            />
            <label className='mb-0' htmlFor="emailFloatingInput">Email</label>
            {/* Error hint */}
            {this.state.emailError && <small className="text-danger text-start d-block mt-1">{this.state.errorMessage}</small>}
          </div>
          {/* Submit button */}
          <button className="btn btn-primary py-2" disabled={this.state.loading} onClick={this.handleSendPassword.bind(this)} >Recuperar contraseña</button>
          <small>
            <a href="https://storage.googleapis.com/talana-seleccion-static/T%26C_Selecci%C3%B3n_clientes.pdf" target='_blank'>Términos y Condiciones</a>
            &nbsp;y <a href="https://storage.googleapis.com/talana-seleccion-static/Pol%C3%ADtica_de_Privacidad_Selecci%C3%B3n_Talana.pdf" target='_blank'>Políticas de Privacidad</a> de Talana
          </small>
        </div>
      </Modal>
    )
  }
}
