import React from 'react';
import ImageChecked from '../../../assets/images/image_base64/img_checked';
import { connect } from 'react-redux';

const mapStateToProps = state => {
  return {
    applicantSession: state.applicantSession
  };
}

class ApplyOfferConnect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render () {
    return(
      <div className="container">
        <div className="center">
          <div className="image-apply">
            <ImageChecked/>
            <br/><br/>
          </div>
          <div>
            <h3 className="offer-aply" style={{color: 'rgba(34, 34, 191, 1)'}}> {this.props.origin === 'kc' ? '¡Tu postulación ha sido realizada con éxito!' : '¡Tu postulación está siendo procesada!'}</h3>
            <br/>
          </div>
          <div>
            <div className="label-company">
              <p className="size-13">{this.props.origin === 'kc' ? "Ahora recibirás un email de Talana con los accesos y claves para rendir algunas" : "En unos instantes, recibirás un correo de Talana con el estado de tu postulación."}</p>
              <p className="size-13">{this.props.origin === 'kc' ? "pruebas laborales que forman parte del proceso de selección para este cargo" : ""}</p>
            </div>
            <br/>
            <p className="p-color-gray">Si no lo encuentras en el inbox, revisa las carpetas de <strong style={{color: 'gray'}}>promociones/notificaciones</strong></p>
          </div>
          <br/>
          <div className="line"></div>
          <br/><br/>
        </div>
      </div>
    );
  }
};

const ApplyOffer = connect(mapStateToProps)(ApplyOfferConnect)
export default ApplyOffer;
