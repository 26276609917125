import React from 'react';
import SelectComponent from '../../../common/inputs/select';
import Mensaje from '../../../common/messages/mensajeError';
import axios from 'axios';
import { formatCountrySelect } from '../../../assets/methods/selects';
import { ValidatePhone } from '../../../assets/methods/validatePhone';
import { ValidarRut } from '../../../assets/methods/validar_rut';
import { applicantSession } from '../../../store/actions/login_applicant';
import { connect } from 'react-redux';
import ReactPhoneInput, { parsePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

const kc_url = process.env.REACT_APP_KEYCLOUD_HOST;

const mapStateToProps = state => {
  return { applicant: state.applicantSession.applicant }
}


const config = { headers: {'Access-Control-Allow-Origin': '*'} };

class ApplicantUpdateConnected extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nameValue: this.props.applicant.nombres,
      dniValue: this.props.applicant.dni,
      paternoValue: this.props.applicant.apellido_paterno,
      maternoValue: this.props.applicant.apellido_materno,
      countryValue: '',
      phoneValue: this.props.applicant.telefono,
      validPhone: true,
      emailValue: this.props.applicant.email,
      emailConfirmationValue: this.props.applicant.email,
      error: false,
      errorMessage: '',
      countries: [],
      countryId: this.props.applicant.country_id,
      nameEmpty: false,
      paternoEmpty: false,
      dniEmpty: false,
      countryEmpty: false,
      emailEmpty: false,
      emailConfirmationEmpty: false,
      modalMail: false,
    }
  }

  getCountry() {
    const url = kc_url + "/api/v2/company/countries";
    axios.get(url, config)
    .then(response => {
      const payload = response.data.data;
      this.setState({
        countries: formatCountrySelect(payload)
      });
      for (var i = 0; i < payload.countries.length; i++) {
        if (payload.countries[i].id === this.props.applicant.country_id) {
          this.setState({
            countryValue: { object: payload.countries[i], label: payload.countries[i].nombre, value: this.props.applicant.country_id }
          });
          break
        }
      }
    })
    .catch(error => { console.log(error); });
  }

  componentDidMount() {
    this.getCountry();
  }

  onChangeName = (event) => {
    this.setState({nameValue: event.target.value});
  }

  onChangeDNI = (event) => {
    this.setState({dniValue: event.target.value});
  }

  onChangePaterno = (event) => {
    this.setState({paternoValue: event.target.value});
  }

  onChangeMaterno = (event) => {
    this.setState({maternoValue: event.target.value});
  }

  onChangeCountry = (value) => {
    this.setState({countryValue: value});
  }

  onChangePhone = (value) => {
    this.setState({phoneValue: value});

    if (value?.length > 9) {
      if(!isValidPhoneNumber(value)){
        this.state.validPhone = false;
        this.setState({ error: true, errorMessage: 'Teléfono Inválido' });
      }
      else {
        this.state.validPhone = true;
        this.setState({ error: false });
      }
    } else if (value?.length > 0) {
      this.state.validPhone = false;
      this.setState({ error: true, errorMessage: 'Teléfono Inválido' });
    } else {
      this.state.validPhone = true;
      this.setState({ error: false });
    }
  }

  onChangeEmail = (event) => {
    this.setState({emailValue: event.target.value});
  }

  onChangeConfirmationEmail = (event) => {
    let newEmail = event.target.value;
    this.setState({emailConfirmationValue: newEmail});
    if (newEmail) {this.setState({emailConfirmationEmpty: false});}
    else { this.setState({emailConfirmationEmpty: true});}
  }

  validate() {
    if (this.state.dniValue){
      this.setState({ countryEmpty: (this.state.countryValue) ? false : true });
      if ( !this.state.countryValue ) {
        this.setState({ error: true, errorMessage: '(*) Algunos campos no pueden estar vacíos'})
        return false
      }

      const validarDni = ValidarRut(this.state.dniValue);
      if (this.state.countryValue.value === 43 && !validarDni.estado) {
        this.setState({ error: true, errorMessage: 'RUT inválido', dniEmpty: true })
        return false
      };
    }

    if (this.state.emailValue !== this.state.emailConfirmationValue) {
      this.setState({ error: true, errorMessage: 'Emails ingresados no coinciden', emailEmpty: true, emailConfirmationEmpty: true })
      return false
    }

    if(!this.state.validPhone) {
      this.setState({ error: true, errorMessage: 'Teléfono Inválido' })
      return false
    }

    return true
   }


  onClickUpdate() {
    this.setState({nameEmpty: (this.state.nameValue) ? false : true, paternoEmpty: (this.state.paternoValue) ? false : true, emailConfirmationEmpty: (this.state.emailConfirmationValue) ? false : true, emailEmpty: (this.state.emailValue) ? false : true, dniEmpty: false, countryEmpty: false});
    if (!this.state.nameValue || !this.state.paternoValue || !this.state.emailValue || !this.state.emailConfirmationValue ) {
      this.setState({
        error: true,
        errorMessage: '(*) Algunos campos no pueden estar vacíos'
      })
    } else {
      if (this.validate()) {
        const url = kc_url + "/api/v2/applicant/applicant";
        const applicant = {
          nombres: this.state.nameValue,
          apellido_paterno: this.state.paternoValue,
          apellido_materno: this.state.maternoValue,
          email: this.state.emailValue
        };

        if (this.state.dniValue) {
          applicant.country_id = this.state.countryValue.value
          applicant.dni = this.state.dniValue
        }

        if (this.state.phoneValue) {
          let numberValid2;
          if (!(String(this.state.phoneValue).includes("+"))){
          numberValid2 = "+".concat(String(this.state.phoneValue))} else {
            numberValid2 = String(this.state.phoneValue);
          };
          applicant.telefonoCountry = parsePhoneNumber(numberValid2).country
          applicant.telefono = this.state.phoneValue
        };
        this.btn.setAttribute("disabled", "disabled");
        axios.patch(url, {applicant}, {withCredentials: true})
        .then(response => {
          const applicant = response.data.data.applicant;
          this.props.dispatch(applicantSession(applicant));
          this.props.closeModal();
        })
        .catch(error =>  {
          this.btn.removeAttribute("disabled");
          let errores = error.response.data.messages;
          if (typeof error.response.data.messages == 'string') {
            errores = [errores]
          }
          let estado = false;
          if (errores && errores[0] === 'Email no es válido'){
            estado = true;
          };
          this.setState({
            errorMessage: errores,
            error: true,
            emailEmpty: estado
          })
          console.log(error.response.data.messages); });
      }
    }
  }

  handleCloseModal = () => {
    this.setState({ openModal: false })
  }

  onPastePrevent(e) {
    e.preventDefault();
    return false;
  }

  render() {
    return (
      <div className="p-4 text-start" style={this.state.modalMail ? {display: 'none'} : {} }>
          <div className="form-title_registrame">
            <p className="login-registrame">ACTUALIZA TUS DATOS PERSONALES</p>
          </div>
        <div className="left">
          { this.state.error ? <Mensaje class={"red"} mensaje={this.state.errorMessage} /> : null }
          <div className="row space-down gy-3">
            <div className="col-md-6">
              <label className={"tcn-label-register"}>Nombre</label>
              <input
                name="Name"
                className="form-control tcn-input"
                value={this.state.nameValue ? this.state.nameValue : ''}
                onChange={this.onChangeName}
                style={(this.state.nameEmpty) ? {borderColor: 'red'} : {}}
              />
            </div>
            <div className="col-md-6">
              <SelectComponent
                isMulti={false}
                title={"País (emisor doc. identidad)"}
                classSelect={"tcn-label-register"}
                onChange={this.onChangeCountry}
                options={this.state.countries}
                value={this.state.countryValue ? this.state.countryValue : ''}
                isEmpty={(this.state.countryEmpty) ? true : false}
              />
            </div>
          </div>
          <div className="row space-down gy-3">
            <div className="col-md-6">
              <label className={"tcn-label-register"}>Apellido Paterno</label>
              <input
                name="Paterno"
                className="form-control tcn-input"
                value={this.state.paternoValue ? this.state.paternoValue : ''}
                onChange={this.onChangePaterno}
                style={(this.state.paternoEmpty) ? {borderColor: 'red'} : {}}
              />
            </div>
            <div className="col-md-6">
              <label className={"tcn-label-register"}>RUT/DNI/Pasaporte</label>
              <input
                name="DNI"
                className="form-control tcn-input"
                value={this.state.dniValue ? this.state.dniValue : ''}
                onChange={this.onChangeDNI}
                style={(this.state.dniEmpty) ? {borderColor: 'red'} : {}}
              />
            </div>
          </div>
          <div className="row space-down gy-3">
            <div className="col-md-6">
              <label className={"tcn-label-register"}>Apellido Materno</label>
              <input
                name="Materno"
                className="form-control tcn-input"
                value={this.state.maternoValue ? this.state.maternoValue : ''}
                onChange={this.onChangeMaterno}
              />
            </div>
            <div className="col-md-6">
              <label className={"tcn-label-register"}>Teléfono</label>
              <div className="form-control phone-number" style={!this.state.validPhone ? {borderColor: 'red'} : {}}>
                <ReactPhoneInput
                  name="Phone"
                  value={this.state.phoneValue}
                  onChange={this.onChangePhone}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
            <button type="button" className="btn btn-primary w-100" ref={btn => { this.btn = btn; }}  onClick={this.onClickUpdate.bind(this)}>
              Confirmar
            </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const ApplicantUpdate = connect(mapStateToProps)(ApplicantUpdateConnected);
export default ApplicantUpdate;
