import { ADD_OFFER, GET_OFFERS } from '../constants/index';

const initialState = {
  offers: []
}

export const addOffer = (state = initialState, {type, offer}) => {
  switch (type) {
    case ADD_OFFER:
      return {
      offers: state.offers.concat(offer)
      }
    default:
      return state;
  }
}

export const offersList = (state = {}, {type, payload}) => {
  switch (type) {
    case GET_OFFERS:
      return {
        ...state,
        offers: payload.offers,
        resultsCount: payload.total_offers,
        pagesCount: payload.total_pages
      };
    default:
      return state;
  }
}
