import React from 'react';
import Modal from 'react-responsive-modal';
import '../index.css';

export default class ModalTcn extends React.Component {
  render() {

    const modalStyle = {
      margin: '90px auto',
      border: 'rgba(10, 7, 22, 0.24)',
      boxShadow: '0 5px 15px rgba(0, 0, 0, 0.16)',
      borderRadius: '10px',
    }

    const modalContentStyle = {
      maxWidth: 900,
    }

    return (
      <div>
        <Modal
          open={this.props.open}
          onClose={this.props.onClose}
          styles= {{
            modal: this.props.modalSize ? { ...this.props.modalSize, ...modalStyle } : modalStyle,
            overlay: { backgroundColor: 'rgba(0, 0, 0, 0.64)' },
            closeIcon: {color: 'white'}
          }}
          closeIconSize={24}
        >
        <div style={modalContentStyle}>
          <div style={this.props.modalStyle}>
            <div className="text-center">{this.props.container}</div>
            { this.props.footer ?
              <div className="modal-tcn-footer">
                <button className="btn btn-default tcn-button-back" onClick={this.props.modalBackOnClick}>{this.props.buttonBack}</button>
                <button className="btn btn-primary tcn-button-go-on" onClick={this.props.modalGoOnClick}>{this.props.buttonGoOn}</button>
              </div>
              :
              null
            }
          </div>
        </div>
        </Modal>
      </div>
    )
  }
}
