const { validate, format } = require('rut.js');

export const ValidarRut = (dni) => {
  const hash = { estado: false, dni: dni };
  const rut = format(hash.dni);
  if (validate(rut)) {
    hash.estado = true;
    hash.dni = rut;
  };
  return hash;
};
