import React from 'react';
import SelectComponent from '../../../common/inputs/select';
import axios from 'axios';
import { getOffers } from '../../../store/actions/offerlist';
import { setNameFilter, setRegionFilter, setCommuneFilter, setCurrentPage } from '../../../store/actions/offer_filters';
import { getCommunes, getRegions } from '../../../common/functions/get_offers_info';
import { connect } from 'react-redux';

const url = process.env.REACT_APP_BACKEND_HOST + '/company/public_offers/';
const kc_api_key = process.env.REACT_APP_KC_API_KEY
var config = { headers: {'Access-Control-Allow-Origin': '*'} };
axios.defaults.headers.common['Authorization'] = kc_api_key;

const mapStateToProps = state => {
  return {
    offersList: state.offersList
  }
}

class PanelFilterConnect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      offerName: '',
      offerNameError: false,
      regionValue: '',
      communeValue: '',
      regionName: '',
      regionOptions: [],
      communesOptions: [],
      allOffers: [],
    }
  }

  nameContainsHandler(value) {
    this.setState({ offerName: value });
    this.props.dispatch(setNameFilter(value));
    if (!value || value.length > 3) {
      this.setState({offerNameError: false});
    }
  }

  handlerRegion(value) {
    const offers = this.state.allOffers;
    let communes = getCommunes(offers, value.label);
    this.setState({regionValue: value, regionName: value.label, communesOptions: communes});
    this.props.dispatch(setRegionFilter(value.label));
    if (value != this.state.regionValue) {
      this.setState({communeValue: ''});
    }
  }

  handlerCommune(value) {
    this.setState({communeValue: value});
    this.props.dispatch(setCommuneFilter(value));
  }

  handlerBuscar() {
    if (this.state.offerName && this.state.offerName.length < 4) {
      this.setState({offerNameError: true});
      return;
    }
    this.props.dispatch(setCurrentPage(0));
    this.getOffers();
  }

  getOffers() {
    const params = {
      company_id: this.props.company,
      current_page: 0,
      filter: true,
      filters: {
        name_cont: this.state.offerName,
        region_cont: this.state.regionName,
        communes: this.state.communeValue,
      }
    }
    axios.get(url, {params}, config)
    .then(response => {
      this.props.dispatch(getOffers(response.data));

    })
    .catch(error => { console.log(error.response) });
  }

  handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.handlerBuscar()
    }
  }

  render() {
    const offers = this.props.offersList.offers;
    let regions =  this.state.regionOptions;
    if (offers && regions.length === 0) {
      regions = getRegions(offers);
      this.setState({regionOptions: regions, allOffers: offers})
    }
    return (
      <div className='d-flex justify-content-center'>
        <div className='container-fluid col-8 col-md-12 col-lg-11 px-0'>
          <div className='row gy-2'>
            <div className='col-md-4 margin-top-label'>
              <label>Nombre de la oferta o palabra clave</label>
              <input
                type="text"
                className="form-control"                            
                placeholder={"ej: Ejecutivo de Finanzas"}
                onChange={(e) => this.nameContainsHandler(e.target.value)}
                value={this.state.offerName}
                onKeyDown={this.handleKeyDown}
                style={this.state.offerNameError ? {borderColor: 'red'} : null}
              />
              {this.state.offerNameError ? <p style={{color: 'red', marginTop: '8px'}}>Debes ingresar mas de 3 letras</p> : null }
            </div>
            <div className='col-md-3 margin-top-label'>
              <SelectComponent
                title={"Región"}
                multi={false}
                options={this.state.regionOptions}
                placeholder={""}
                onChange={(e) => this.handlerRegion(e)}
                value={this.state.regionValue}
                onKeyDown={this.handleKeyDown}
              />
            </div>
            <div className='col-md-3 margin-top-label'>
              <SelectComponent
                title={"Comuna"}
                multi={true}
                options={this.state.communesOptions}
                placeholder={""}
                onChange={(e) => this.handlerCommune(e)}
                value={this.state.communeValue}
                onKeyDown={this.handleKeyDown}
              />
            </div>
            <div className='col-md-2 vstack justify-content-end'>
              <button className="btn btn-primary w-100" onClick={this.handlerBuscar.bind(this)}>Filtrar</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const PanelFilter = connect(mapStateToProps)(PanelFilterConnect);
export default PanelFilter;
