
export const getRegions = (offers) => {
  const used = {};
  const regions = [{label: 'TODAS', value: 'TODAS'}];
  offers.forEach(function(off) {
    let reg = off.region;
    if (!used[reg]) {
      used[reg] = 1;
      regions.push({label: reg, value: reg});
    }
  })

  return regions;
}

export const getCommunes = (offers, region) => {
  const used = {};
  const communes = [];
  offers.forEach(function(off) {
    let com = off.commune;
    if (!used[com] && off.region === region) {
      used[com] = 1;
      communes.push({label: com, value: com});
    }
  })
  return communes;
}
