import React from 'react';
import Modal from 'react-responsive-modal';
import { SyncLoader } from 'react-spinners';
import '../index.css';

export default class LoadingModal extends React.Component {
  constructor(props) {
    super(props)
  }

  onClose() {
    console.log('Guardando datos...')
  }

  render() {
    const marginTop = this.props.marginTop ? this.props.marginTop + ' auto' : '500px auto';
    const modalStyle = {
      backgroundColor: 'transparent',
      position: 'relative',
      borderRadius: 5,
      margin: marginTop,
      border: 'none',
      boxShadow: 'none'
    }

    const modalContentStyle = {
      maxWidth: 330,
      maxHeight: 330,
    }

    return (
      <Modal
        open={this.props.open}
        onClose={this.onClose.bind(this)}
        styles= {{ modal: modalStyle, overlay: {backgroundColor: 'transparent'}}}
        showCloseIcon={false}
      >
        <div className="modal-container" style={modalContentStyle}>
          <div className="center">
            <SyncLoader color='#007bff'/>
          </div>
        </div>
      </Modal>
    )
  }
}
