import React, { Component } from 'react';
import PanelFilter from '../components/panel_filter';
import PanelIndex from '../components/panel_index';
import { connect } from 'react-redux';
import { imagesCompany, notFound } from '../../../store/actions/images_company';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import DefaultCompanyLogo from '../../../assets/images/logo_empresa.png';
import NoCompanyFound from '../../../assets/images/nocompany.png';

const backend_url = process.env.REACT_APP_BACKEND_HOST;
const kc_api_key = process.env.REACT_APP_KC_API_KEY;
var config = { headers: {'Access-Control-Allow-Origin': '*'} };
axios.defaults.headers.common['Authorization'] = kc_api_key;

const mapStateToProps = state => {
  return {
    applicantSession: state.applicantSession,
    imagesCompany: state.imagesCompany,
    notFound: state.notFound
  }
}

class OfferIndexConnect extends Component {

  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      modalContent: null,
      not_found: false,
      loading: true
    }
  }

  getCompany() {
    const url = backend_url + '/company/companies_domain';
    const params = {
      domain: this.props.match.params.company,
    };
    axios.get(url, {params}, config)
    .then(response => {
      const company = response.data.data.company;
      this.props.dispatch(imagesCompany(company));
    })
    .catch((error) => {
      this.setState({ not_found: true });
      this.props.dispatch(notFound());
      console.log(error.response);
    });
  }

  componentDidMount() {
    this.getCompany();
  }

  render() {
    const company = this.props.imagesCompany.images_company;
    if (company) {
      return (
        <div className='container'>
          <PanelFilter
            company={company.id}
          />
          <div className='container-title'>
            <h2 className='index-title mt-5'>OFERTAS LABORALES - {company.razon_social.toUpperCase()}</h2>
          </div>
          <PanelIndex
            company={company.id}
            subdomain={company.domain}
          />
        </div>
      );
    } else {
      return (
        <div className='container'>
          { this.state.not_found ?
          <div className="noCompany pb-5">
            <div className="noCompanyImage">
              <img src={NoCompanyFound} alt=""/>
            </div>
            <div className="noCompanyMsg">
              <p className="noCompany1">Lo sentimos :(</p>
              <p className="noCompany2">No pudimos encontrar la página que buscabas.</p>
            </div>
          </div>
          : null }
        </div>
      )
    }
  }
}

const OfferIndex = connect(mapStateToProps)(OfferIndexConnect);
export default OfferIndex;
