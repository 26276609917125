
import { addOffer, offersList } from './offerlist'
import { applicantSession } from './login_applicant';
import { imagesCompany, notFound } from './images_company';
import { companyConfidentiality } from './confidentiality';
import { offerFilters } from './offer_filters';
import { combineReducers } from 'redux';

const reducer = combineReducers({
  addOffer: addOffer,
  applicantSession: applicantSession,
  offersList: offersList,
  imagesCompany: imagesCompany,
  offerFilters: offerFilters,
  notFound: notFound,
  companyConfidentiality: companyConfidentiality
});

export default reducer;
