import React from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';

const backend_url = process.env.REACT_APP_BACKEND_HOST;
const kc_api_key = process.env.REACT_APP_KC_API_KEY;
var config = { headers: {'Access-Control-Allow-Origin': '*'} };
axios.defaults.headers.common['Authorization'] = kc_api_key;

class OtherOffers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      offerId: props.offerId,
      company_id: props.company_id,
      offers: []
    }
  }

  getOtherOffers() {
    const id = this.state.offerId;
    const company_id = this.state.company_id;
    const url = backend_url + '/company/offer/other_offers';
    const params = {
      company_id: company_id,
      id: id,
    }
    axios.get(url, {params}, config)
    .then(response => {
      this.setState( { offers: response.data.data.offers });
    })
    .catch(error => { console.log(error.response) });
  }

  componentDidMount() {
    this.getOtherOffers();
  }

  static getDerivedStateFromProps(nextProps, prevState){
    if(nextProps.offerId !== prevState.offerId){
      return {offerId: nextProps.offerId}
    }
    else return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.offerId !== this.props.offerId){
      this.setState({offerId: this.props.offerId})
      this.getOtherOffers();
    }
  }

  render() {
    const subdomain = this.props.subdomain;
    const url = '/' + subdomain;

    const offersList = this.state.offers.map(function(offer, index) {
      const offer_url = url + '/' + offer.id;
      return (
        <div className="row" key={index}>
          <div className="col-md-12">
            <NavLink to={{pathname: offer_url, company_id: offer.company_id, subdomain: subdomain}} className="other-cargo-span">{offer.name}</NavLink>
            <p className="other-region-p">{offer.region}</p>
          </div>
        </div>
      )
    });
    return (
      <div className="p-3">
        <h2 className="other-offers-title mb-5">MÁS OFERTAS LABORALES "{this.props.razon_social.toUpperCase()}"</h2>
        {offersList}
        <NavLink to={{pathname: url}}>
          <div className="other-offers-title mt-4">
            <button type="button" className="btn btn-primary"><b>Ver todas las ofertas</b></button>
          </div>
        </NavLink>
      </div>
    )
  }
}

export default OtherOffers;
