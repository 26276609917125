import { createStore, applyMiddleware } from "redux";
import reducer from "../reducers/index";
import thunk from "redux-thunk";
import { loadState, saveState } from "./localStorage";

const persistedState = loadState();

const store = createStore(
  reducer,
  persistedState,
  applyMiddleware(thunk)
);

store.subscribe(() => {
  let applicantSession = store.getState().applicantSession;
  saveState({
    applicantSession: applicantSession
  });
});

export default store;
