import React from 'react';
import ModalTcn from '../../../common/modal';
import { NavDropdown } from 'react-bootstrap';
import ApplicantUpdate from './applicant_update';
import { applicantSession } from '../../../store/actions/login_applicant';
import { connect } from 'react-redux';
import axios from 'axios';
import Cookies from 'js-cookie';

const kc_url = process.env.REACT_APP_KEYCLOUD_HOST;
const mapStateToProps = state => {
  return {
    applicantSession: state.applicantSession
  }
}
class DropdownApplicantConnect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      modalContent: null
    }
  };

  handleUpdate = () => {
    this.setState({ openModal: true, modalContent: <ApplicantUpdate closeModal={this.handleCloseModal.bind(this)}/>,  })
  }

  handleCloseSession = () => {
    const url = kc_url + "/api/v2/applicant/authentication/" + this.props.applicantSession.applicant.id;
    axios.patch(url, {}, {withCredentials: true})
    .then(response => {
      Cookies.remove('session_keyjobs');
      this.props.dispatch(applicantSession(null));
    })
    .catch(error =>{
      console.log(error)
      this.setState({ error: true, errorText: 'Usuario o contraseña incorrecta' });
   });
  };

  handleCloseModal = () => {
    this.setState({ openModal: false })
  }

  render() {
    return (
      <>
        <NavDropdown alignRight title={this.props.applicantName} id="basic-nav-dropdown" className="navbarApplicant">
          <NavDropdown.Item onClick={this.handleUpdate}>Editar datos</NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item onClick={this.handleCloseSession}>Cerrar Sesión</NavDropdown.Item>
        </NavDropdown>
        <ModalTcn
          open={this.state.openModal}
          onClose={this.handleCloseModal}
          container={this.state.modalContent}
          footer={false}
          title={""}
        />
      </>
    );
  };
};

const DropdownApplicant =  connect(mapStateToProps)(DropdownApplicantConnect);
export default DropdownApplicant;
