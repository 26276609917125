import React from 'react';
import { FacebookShareButton, FacebookIcon, TwitterIcon, TwitterShareButton } from 'react-share';

const OfferFooter = (props) => {
  return (
    <div className="my-4">
      <div className="row align-items-center justify-content-between gap-3">
        <div className="col col-md-8">
          {props.showButton? <button type="button" className="btn btn-primary btn-lg px-5 fs-4 py-1 fw-bold" onClick={props.onClick}>Postular</button> : <p className="already-applied" >¡Ya has postulado a esta oferta!</p>}    
        </div>
        <div className="col-5 col-md-3 todas-redes-sociales">
          <div className='redes-sociales'>
            <FacebookShareButton
              url={props.shareUrl}
              quote={props.shareText}
              hashtag={'#KeyClouding'}>
              <FacebookIcon
                size={32}
                round />
            </FacebookShareButton>
          </div>
          <div className='redes-sociales'>
            <TwitterShareButton
              url={props.shareUrl}
              title={props.shareText}
              hashtags={['KeyClouding', props.company]}
              via={'keyclouding'}>
              <TwitterIcon
                size={32}
                round />
            </TwitterShareButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OfferFooter;
