import React from 'react';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  return {}
}

class OfferInfoConnected extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          <p className="tcn-region-p">Publicado por: "{this.props.company}"</p>
          <span className="tcn-cargo-span">{this.props.cargoName}</span>
          <p className="tcn-region-p">{this.props.commune}, {this.props.region}</p>
          {this.props.teletrabajo ? <p className="tcn-region-p" style={{marginTop: '-10px'}}>{this.props.modality}, {this.props.teletrabajo}</p> : 
          <p className="tcn-region-p" style={{marginTop: '-10px'}}>{this.props.modality}</p>}
          {}
          <p className="tcn-requerimientos">Descripción</p>
          <br/>
          <p className="tcn-description-p wordBreak m-0 me-sm-4" style={{textAlign: 'justify', lineHeight: '1.5', marginRight : '40px'}} dangerouslySetInnerHTML={{ __html: this.props.description }} />
          <br/>
          <p className="tcn-requerimientos">Requerimientos del cargo</p>
          <br/>
          <p className="tcn-description-p wordBreak" style={{textAlign: 'justify', lineHeight: '1.5', marginRight : '40px'}} dangerouslySetInnerHTML={{ __html: this.props.demands }} />
          <br/>
          {this.props.benefit ? <p className="tcn-requerimientos">Beneficios</p> : null}
          <br/>
          {this.props.benefit ? <p className="tcn-description-p wordBreak" style={{textAlign: 'justify', lineHeight: '1.5', marginRight : '40px'}} dangerouslySetInnerHTML={{ __html: this.props.benefit }} /> : null}
          <br/>
        </div>
      </div>
    )
  }
}

const OfferInfo = connect(mapStateToProps)(OfferInfoConnected);
export default OfferInfo;
