import React from 'react';
import Navbar from '../components/navbar';
import { connect } from 'react-redux';

const mapStateToProps = state => {
  return {
    imagesCompany: state.imagesCompany,
    notFound: state.notFound,
    confidentiality: state.companyConfidentiality.confidentiality
  }
}

class LayoutConnected extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      children: props.children.type.name
    }
  }

  render() {
    return (
      <>
        <Navbar children={this.state.children}/>
        <div className="container-fluid">
          {this.props.children}
        </div>
      </>
    )
  }
}

const LayoutConnect = connect(mapStateToProps)(LayoutConnected);
export default LayoutConnect;
