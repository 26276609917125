import { NAME_FILTER, REGION_FILTER, COMMUNE_FILTER, CURRENT_PAGE } from '../constants/index';

export function setNameFilter(payload) {
  return { type: NAME_FILTER, payload: payload }
}

export function setRegionFilter(payload) {
  return { type: REGION_FILTER, payload: payload }
}

export function setCommuneFilter(payload) {
  return { type: COMMUNE_FILTER, payload: payload }
}

export function setCurrentPage(payload) {
  return { type: CURRENT_PAGE, payload: payload }
}
