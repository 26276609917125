import React, { Component } from 'react';
import { Routes } from './routes';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store/store/tcn';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module'

import './App.css';
import './assets/css/application.scss';

const tagManagerArgs = {
  gtmId: 'GTM-WN36XQC'
}

TagManager.initialize(tagManagerArgs)

const ga_code = process.env.REACT_APP_GA_TRACKER;

function initializeReactGA() {
    ReactGA.initialize(ga_code);
    ReactGA.pageview(window.location.pathname);
}

initializeReactGA();

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <Routes />
        </Router>
      </Provider>

    );
  }
}

export default App;
