import LogoTalana from '../../../../assets/images/talana-logo-white.svg';

const Footer = () =>  {
  return (
    <div className="bg-primary pb-5 p-3">
      <div className="container-fluid" style={{maxWidth: '900px'}}>
        <div className="row align-items-end gap-4">
          <div className="col-12 col-sm-3">
            <a href="https://web.talana.com/" target="_blank"><img src={LogoTalana} alt="talana" width="fit-content" height={36} style={{maxWidth: "min-content"}} /></a>
          </div>
          <div className="col row align-items-center gap-3 small fw-bold text-decoration-none">
            <div className="col">
              <a href="https://web.talana.com/portal-ayuda-postulante" target="_blank" className='link-light'>
                Ayuda al postulante
              </a>
            </div>
            <div className="col">
              <a href="https://storage.googleapis.com/talana-seleccion-static/T%26C_Seleccion-postulantes.pdf" target="_blank" className='link-light'>
                Términos y condiciones
              </a>
            </div>
            <div className="col">
              <a href="https://storage.googleapis.com/talana-seleccion-static/Pol%C3%ADtica_de_Privacidad_Selecci%C3%B3n_Talana.pdf" target="_blank" className='link-light'>
                Políticas de privacidad
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer;
