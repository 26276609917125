import React from 'react';
import SelectComponent from '../../../common/inputs/select';
import Accept  from '../../../common/messages/Accept';
import Mensaje from '../../../common/messages/mensajeError';
import axios from 'axios';
import { formatCountrySelect } from '../../../assets/methods/selects';
import { ValidarRut } from '../../../assets/methods/validar_rut';
import { ValidateEmail } from '../../../assets/methods/validateEmail';
import ReactPhoneInput, { parsePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import Modal from 'react-responsive-modal';
import { mdiAlertCircle } from '@mdi/js'
import Icon from '@mdi/react';

const kc_url = process.env.REACT_APP_KEYCLOUD_HOST;

const config = { headers: {'Access-Control-Allow-Origin': '*'}, withCredentials: true };

class ApplicantRegisterConnected extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nameValue: '',
      dniValue: '',
      paternoValue: '',
      maternoValue: '',
      countryValue: '',
      phoneValue: '',
      validPhone: true,
      emailValue: '',
      emailConfirmationValue: '',
      error: false,
      errorMessage: '',
      countries: [],
      countryId: 0,
      nameEmpty: false,
      paternoEmpty: false,
      dniEmpty: false,
      countryEmpty: false,
      emailEmpty: false,
      emailConfirmationEmpty: false,
      showConfirmationModal: false,
      geoData: {
        countryCode: '',
      }
    }
  }

  getCountry() {
    const url = kc_url + "/api/v2/company/countries"
    axios.get(url, config)
    .then(response => {
      const payload = response.data.data;
      this.setState({
        countries: formatCountrySelect(payload)
      });
    }).then(() => {
      //ipapi tiene un límite de 30,000 llamadas por mes de forma gratis. Si en algun momento KJ tiene éxito, revisar.
      axios.get('https://ipapi.co/json/').then((response) => {
        let data = response.data;
        this.setState({geoData:{countryCode: data.country_code.toLowerCase()}});
        const countrySelected = this.state.countries.find(country => country.object.codigo.toLowerCase() === this.state.geoData.countryCode)
        this.setState({countryValue: countrySelected})

      }).catch((error) => {
        this.setState({geoData:{countryCode: 'cl'}});
        console.log(error);
      });
    })
    .catch(error => {
      console.log(error);
    });
  }

  onCloseModalMail() {
    this.props.closeModal();
  }

  componentDidMount() {
    this.getCountry();
  }

  onChangeName = (event) => {
    let newName = event.target.value;
    this.setState({nameValue: newName});
    if (newName) {this.setState({nameEmpty: false});}
    else { this.setState({nameEmpty: true});}
  }

  onChangeDNI = (event) => {
    let newDni = event.target.value;
    this.setState({dniValue: newDni});
    if (newDni) {this.setState({dniEmpty: false});}
    else { this.setState({dniEmpty: true});}
  }

  onChangePaterno = (event) => {
    let newpaterno = event.target.value;
    this.setState({paternoValue: event.target.value});
    if (newpaterno) {this.setState({paternoEmpty: false});}
    else { this.setState({paternoEmpty: true});}
  }

  onChangeMaterno = (event) => {
    this.setState({maternoValue: event.target.value});
  }

  onChangeCountry = (value) => {
    let newCountry = value;
    this.setState({countryValue: newCountry});
    if (newCountry) {this.setState({countryEmpty: false});}
    else { this.setState({countryEmpty: true});}
  }

  onChangePhone = (value) => {
    this.setState({phoneValue: value});
    
    if (value?.length > 9) {
      if(!isValidPhoneNumber(value)){
        this.state.validPhone = false;
        this.setState({ error: true, errorMessage: 'Teléfono Inválido' });
      }
      else {
        this.state.validPhone = true;
        this.setState({ error: false });
      }
    } else if (value?.length > 0) {
      this.state.validPhone = false;
      this.setState({ error: true, errorMessage: 'Teléfono Inválido' });
    } else {
      this.state.validPhone = true;
      this.setState({ error: false });
    }
  }

  onChangeEmail = (event) => {
    let newEmail = event.target.value;
    this.setState({emailValue: newEmail});
    if (newEmail) {this.setState({emailEmpty: false});}
    else { this.setState({emailEmpty: true});}
  }

  onChangeConfirmationEmail = (event) => {
    let newEmail = event.target.value;
    this.setState({emailConfirmationValue: newEmail});
    if (newEmail) {this.setState({emailConfirmationEmpty: false});}
    else { this.setState({emailConfirmationEmpty: true});}
  }

  validate() {
    if (this.state.dniValue) {
      this.setState({ countryEmpty: (this.state.countryValue) ? false : true });
      if ( !this.state.countryValue ) {
        this.setState({ error: true, errorMessage: '(*) Algunos campos no pueden estar vacíos'})
        return false
      }

      const validarDni = ValidarRut(this.state.dniValue);
      if (this.state.countryValue.value === 43 && !validarDni.estado) {
        this.setState({ error: true, errorMessage: 'RUT inválido', dniEmpty: true })
        return false
      };
    }

    if (!ValidateEmail(this.state.emailValue)) {
      this.setState({ error: true, errorMessage: 'El email no es válido', emailEmpty: true })
      return false
    }

    if (this.state.emailValue !== this.state.emailConfirmationValue) {
      this.setState({ error: true, errorMessage: 'Los emails ingresados no coinciden', emailEmpty: true, emailConfirmationEmpty: true })
      return false
    }

    if(!this.state.validPhone) {
      this.setState({ error: true, errorMessage: 'Teléfono inválido' })
      return false
    }

    return true
  }

  onPastePrevent(e) {
    e.preventDefault();
    return false;
  }

  onClickRegister() {
    var registerBtn = document.getElementById('registerBtn');

    this.setState({
      nameEmpty: (this.state.nameValue) ? false : true, 
      paternoEmpty: (this.state.paternoValue) ? false : true, 
      emailConfirmationEmpty: (this.state.emailConfirmationValue) ? false : true, 
      emailEmpty: (this.state.emailValue) ? false : true, dniEmpty: false,
      error: false
    });

    if (!this.state.nameValue || !this.state.paternoValue || !this.state.emailValue || !this.state.emailConfirmationValue) {
      this.setState({
        error: true,
        errorMessage: '(*) Algunos campos no pueden estar vacíos'
      })
    } else {
      if (this.validate() === true) {
        const url = kc_url + "/api/v2/applicant/applicant";
        const applicant = {
          nombres: this.state.nameValue,
          apellido_paterno: this.state.paternoValue,
          apellido_materno: this.state.maternoValue,
          email: this.state.emailValue
        };

        if (this.state.dniValue) {
          applicant.country_id = this.state.countryValue.value
          applicant.dni = this.state.dniValue
        }

        if (this.state.phoneValue) {
          let numberValid;
          if (!(String(this.state.phoneValue).includes("+"))){
          numberValid = "+".concat(String(this.state.phoneValue))} else {
            numberValid = String(this.state.phoneValue);
          };
          applicant.telefonoCountry = parsePhoneNumber(numberValid).country
          applicant.telefono = this.state.phoneValue
        };
        registerBtn.setAttribute("disabled", "disabled"); // TODO: mejorar, usar el framework para esta tarea
        axios.post(url, {applicant}, config,)
        .then(() => {
          this.setState({ showConfirmationModal: true });
        })
        .catch(error =>  {
          registerBtn.removeAttribute("disabled");
          try {
            let errores = error.response.data.messages;
            console.log(errores);
            if (typeof error.response.data.messages == 'string') {
              errores = [errores]
            }
            let estado = false;
            if (errores && errores[0] === 'Email no es válido'){
              estado = true;

            };
            this.setState({
              error: true,
              errorMessage: [errores[0]],
              emailEmpty: estado
            })
          }
          catch(error){
            console.error(error)
            this.setState({
              error: true,
              errorMessage: ['Error en el registro'],
              emailEmpty: false
            })
          }
        });
      }
    }
  }

  render() {
    const confirmationModalStyles = {
      margin: '90px auto',
      boxShadow: 'none',
    }

    const modalStyles = {
      width: '480px',
      display: this.state.showConfirmationModal ? 'none' : null
    }

    const errorInputStyles = {borderColor: 'red', borderWidth: '2px'}

    return (
      <>
        <div className="p-2" style={ modalStyles }>
          <p className="text-center mt-2 mb-3">Crea una cuenta en Talana <b>para postular</b></p>
          <div className="vstack gap-3 text-start">
            { this.state.error && <Mensaje className={"alert alert-danger mb-0"} icon={mdiAlertCircle} mensaje={this.state.errorMessage} /> }
            <div className="row gy-3">
              <div className="col">
                <label className={"tcn-label-register"}>Nombre <span className='red'>*</span></label>
                <input
                  name="Name"
                  placeholder={"Ej: Juan"}
                  className="form-control"                  
                  value={this.state.nameValue}
                  onChange={this.onChangeName}
                  style={(this.state.nameEmpty) ? errorInputStyles : {}}
                />
              </div>
            </div>
            <div className="row gy-3">
              <div className="col-md-6">
                <label className={"tcn-label-register"}>Apellido Paterno <span className='red'>*</span></label>
                <input
                  name="Paterno"
                  placeholder={"Ej: Pérez"}
                  className="form-control"
                  value={this.state.paternoValue}
                  onChange={this.onChangePaterno}
                  style={(this.state.paternoEmpty) ? errorInputStyles : {}}
                />
              </div>
              <div className="col-md-6">
                <label className={"tcn-label-register"}>Apellido Materno</label>
                <input
                  name="Materno"
                  placeholder={"Ej: Cárcamo"}
                  className="form-control"
                  value={this.state.maternoValue}
                  onChange={this.onChangeMaterno}
                />
              </div>
            </div>
            <div className="row gy-3">
              <div className="col-md-6">
                <SelectComponent
                  isMulti={false}
                  title={"País (emisor doc. identidad)"}
                  classSelect={"tcn-label-register"}
                  onChange={this.onChangeCountry}
                  options={this.state.countries}
                  value={this.state.countryValue}
                  isEmpty={(this.state.countryEmpty) ? true : false}
                />
              </div>
              <div className="col-md-6">
                <label className={"tcn-label-register"}>RUT/DNI/Pasaporte</label>
                <input
                  name="DNI"
                  placeholder={"Ej: 15.654.876-9"}
                  className="form-control tcn-input"
                  value={this.state.DNIValue}
                  onChange={this.onChangeDNI}
                  style={(this.state.dniEmpty) ? errorInputStyles : {}}
                />
              </div>
            </div>
            <div className="row gy-3">
              <div className="col-md-6">
                <label className={"tcn-label-register"}>Email <span className='red'>*</span></label>
                <input
                  name="Email"
                  placeholder={"Ej: jperezc@ejemplo.cl"}
                  className="form-control tcn-input"
                  value={this.state.emailValue}
                  onChange={this.onChangeEmail}
                  onPaste={this.onPastePrevent}
                  style={(this.state.emailEmpty) ? errorInputStyles : {}}
                />
              </div>
              <div className="col-md-6">
                <label className={"tcn-label-register"}>Confirma tu Email <span className='red'>*</span></label>
                <input
                  name="EmailConfirmation"
                  placeholder={"Ej: jperezc@ejemplo.cl"}
                  className="form-control tcn-input"
                  value={this.state.emailConfirmationValue}
                  onChange={this.onChangeConfirmationEmail}
                  onPaste={this.onPastePrevent}
                  style={(this.state.emailConfirmationEmpty) ? errorInputStyles : {}}
                />
              </div>
            </div>
            <div className="row gy-3">
              <div className="col">
                <label className={"tcn-label-register"}>Teléfono</label>
                <div className="form-control phone-number" style={!this.state.validPhone ? errorInputStyles : {}}>
                  <ReactPhoneInput
                    value={this.state.phoneValue}
                    onChange={this.onChangePhone}
                  />
                </div>
              </div>
            </div>
            <Accept
              onClick={this.onClickRegister.bind(this)}
            />
          </div>
        </div>
        <Modal
          open={this.state.showConfirmationModal}
          classNames={{modal: 'alert alert-success'}}
          onClose={this.onCloseModalMail.bind(this)}
          styles= {{ modal: confirmationModalStyles, overlay: {backgroundColor: 'transparent'}, closeButton: {marginTop: '22px'}}}
        >
          <Icon path={mdiAlertCircle}
            size={2}
            color="initial"
          />
          <div>
            <b className='title'>¡Estás a un paso de realizar tu postulación!</b>
            <div>
              Por favor revisa tu correo <b>{this.state.emailValue}</b>. Hemos enviado una contraseña, que debes utilizar para aplicar a la oferta de tu interés.
            </div>
          </div>
        </Modal>
      </>
    )
}}

const ApplicantRegister = ApplicantRegisterConnected; //TODO: renombrar
export default ApplicantRegister;
