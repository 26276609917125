import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { applicantSession } from '../../../store/actions/login_applicant';
import Mensaje from '../../../common/messages/mensajeError';
import Cookies from 'js-cookie';
import { ValidateEmail } from '../../../assets/methods/validateEmail';
import Icon from '@mdi/react';
import { mdiEye, mdiAlertCircle } from '@mdi/js';

const kc_url = process.env.REACT_APP_KEYCLOUD_HOST;
const mapStateToProps = state => {
  return {
  }
}

const errorInputStyles = { borderColor: 'red', borderWidth: '2px' }

class ApplicantLogin extends Component {

  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      showPassword: false,
      register: false,
      errorText: '',
      errorEmail: false,
      errorPassword: false,
      error: false
    }

    this.handleEmail = this.handleEmail.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    this.setState({ errorEmail: false, errorPassword: false, errorText: '' })
    if (!this.state.email || !this.state.password ) {
      if (!this.state.email && this.state.password) {
        this.setState({ errorEmail: true, errorText: 'Email no puede estar vacío' })
      } else if (!this.state.password && this.state.email) {
        this.setState({ errorPassword: true, errorText: 'Contraseña no puede estar vacía' })
      } else {
        this.setState({ errorEmail: true, errorPassword: true, errorText: 'Los campos no pueden estar vacíos' })
      }
    } else {
      if (!ValidateEmail(this.state.email)) {
        this.setState({ errorEmail: true, errorText: 'El email ingresado no es válido' })
      }
      else {
        const authentication = { email: this.state.email.toLocaleLowerCase(), password: this.state.password,}
        axios.post(kc_url + "/api/v2/applicant/authentication", { authentication }, {withCredentials: true})
        .then(response => {
          const applicant = response.data.data.applicant;
          Cookies.set('session_keyjobs', 'active');
          this.props.dispatch(applicantSession(applicant));
          this.props.closeModal()
        })
        .catch(error => {
          console.log(error)
          this.setState({ error: true, errorText: 'Usuario o contraseña incorrectos' });
       });
      }
    }
    event.preventDefault();
  }

  handleEmail(event) {
    if (event.target.value) { this.setState({errorEmail: false}) }
    this.setState({email: event.target.value});
  }

  handlePassword(event) {
    if (event.target.value) { this.setState({errorPassword: false}) }
    this.setState({password: event.target.value});
  }

  handleShowPassword() {
    this.setState({ showPassword: !this.state.showPassword });
  }

  handleForgot() {
    this.props.handleForgot()
  }

  render() {
    return (
      <div className='py-2' style={{maxWidth: '362px'}}>
        <form onSubmit={this.handleSubmit} className='vstack gap-3 modal-left-size pt-4 pt-md-0'>
          <div>Ingresa con tu cuenta de <b>Talana para postular</b></div>
          {/* TAB */}
          <div className='d-flex'>
            <div className='mx-auto border-bottom border-4 border-dark py-1 px-3 fw-bold'>Email y contraseña</div>
          </div>
          {/* ALERT */}
          { this.state.errorEmail || this.state.errorPassword || this.state.error && 
            <Mensaje className="alert alert-danger m-0" icon={mdiAlertCircle} mensaje={this.state.errorText} /> 
          }
          {/* FORM */}
          <div>
            <div className="form-floating mb-3">
              <input 
                type="email" className="form-control" id="emailFloatingInput" placeholder="correo@example.com" 
                style={(this.state.errorEmail) ? errorInputStyles : {}} value={this.state.email} onChange={this.handleEmail}
              />
              <label htmlFor="emailFloatingInput">Email</label>
            </div>
            <div className="form-floating">
              <input 
                type={this.state.showPassword ? 'text' : 'password'} className="form-control" id="passwordFloatingInput" placeholder="Contraseña" 
                style={(this.state.errorPassword) ? errorInputStyles : {}} value={this.state.password} onChange={this.handlePassword}
              />
              <label htmlFor="passwordFloatingInput">Contraseña</label>
              <div className="input-group-text border-0 input-button" onClick={this.handleShowPassword.bind(this)}>
                <Icon path={mdiEye}
                  title="Mostrar/ocultar contraseña"
                  role="button"
                  size={1}
                  color="var(--t-gray-600)"
                />
              </div>
            </div>
          </div>
          {/* BUTTONS */}
          <div className="d-flex align-items-center gap-3">
            <a className="w-50" href='#' onClick={this.handleForgot.bind(this)}>Olvidé mi contraseña</a>
            <input className="btn btn-primary w-50 py-2" type="submit" value="Iniciar sesión"/>
          </div>
          <small>
            Al iniciar sesión, estarás aceptando todos los <a href="https://storage.googleapis.com/talana-seleccion-static/T%26C_Selecci%C3%B3n_clientes.pdf" target='_blank'>Términos y Condiciones</a>
            &nbsp;y <a href="https://storage.googleapis.com/talana-seleccion-static/Pol%C3%ADtica_de_Privacidad_Selecci%C3%B3n_Talana.pdf" target='_blank'>Políticas de Privacidad</a> de Talana
          </small>
          <small>¿No tienes cuenta cuenta? <a className='link-primary' role='button' onClick={this.props.register}>Regístrate</a></small>
        </form>
      </div>
    );
  }
}

const ApplicantLog = connect(mapStateToProps)(ApplicantLogin);
export default ApplicantLog
