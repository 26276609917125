import React from 'react';
import axios from 'axios';
import Breadcrumb from '../components/offer_breadcrumb';
import ModalTcn from '../../../common/modal';
import OfferInfo from '../components/offer_info';
import OfferFooter from '../components/offer_footer';
import OtherOffers from '../components/other_offers';
import { connect } from 'react-redux';
import { imagesCompany, notFound } from '../../../store/actions/images_company';
import { setConfidentiality } from '../../../store/actions/confidentiality';
import ApplicantRegister from '../../layout/components/ApplicantRegister';
import ApplicantLogin from '../../layout/components/applicant_login';
import ApplyOffer  from '../components/apply_offer';
import Mensaje from '../../../common/messages/mensajeError';
import RecoverPasswordModal from '../../layout/components/RecoverPasswordModal';
import LoadingModal from '../../../common/loadingModal';
import { NavLink } from 'react-router-dom';
import DefaultCompanyLogo from '../../../assets/images/logo_empresa.png';
import NotFound from '../../../assets/images/noencontrada-ejemplo.png';

const config = { headers: {'Access-Control-Allow-Origin': '*'} };
const portal_url = process.env.REACT_APP_PORTAL_APPLICANT_URL;
const kc_api_key = process.env.REACT_APP_KC_API_KEY;
const backend_url = process.env.REACT_APP_BACKEND_HOST;
axios.defaults.headers.common['Authorization'] = kc_api_key;

const mapStateToProps = state => {
  return {
    applicantSession: state.applicantSession,
    imagesCompany: state.imagesCompany,
    notFound: state.notFound
  }
}
class OfferShowConnect extends React.Component {
  constructor() {
    super();
    this.state = {
      offerId: 0,
      offer: '',
      openModal: false,
      modalSize: null,
      offerApply: true,
      modalContent: null,
      errorText: '',
      error: false,
      loaded: false,
      showButton: true,
      forgot: false,
      loading: false,
      hashedData: {
        company: null,
        offerId: null,
      }
    }
  }

  getCompany() {
    if(this.props.match.path.includes("offers")){
      const url = backend_url + '/company/companies_hash';
      const params = {
        hash: this.props.match.params.offer,
      };
      axios.get(url, {params}, config)
      .then(response => {
        const company = {
          ...response.data.data.company,
          top_url: response.data.data.top_url,
          logo_url: response.data.data.logo_url,
        };
        this.props.dispatch(imagesCompany(company));
        this.setState({
          hashedData: {
            company: response.data.data.company,
            offerId: response.data.data.hash.offer_id,
          }
        })
        this.getOffer();
      })
      .catch(error => {
        this.setState({ not_found: true });
        this.props.dispatch(notFound(true));
        console.log(error.response);
      });
    }
    else{
      const url = backend_url + '/company/companies_domain';
      const params = {
        domain: this.props.match.params.company,
      };
      axios.get(url, {params}, config)
      .then(response => {
        const company = response.data.data.company;
        this.props.dispatch(imagesCompany(company));
        this.getOffer();
      })
      .catch(error => {
        this.setState({ not_found: true });
        this.props.dispatch(notFound(true));
        console.log(error.response);
      });
    }
  }

  getOffer() {
    if(this.props.match.path.includes("offers")){
      const comp = this.state.hashedData.company
      const offer = this.state.hashedData.offerId
      this.setState({ offerId: offer });
      const url = backend_url + 'company/offers/'+offer;
      const params = {
        company_id: comp.id,
      }
      axios.get(url, {params}, config)
      .then(response => {
        this.setState( { offer: response.data.data.offer, loaded: true });
        this.props.dispatch(setConfidentiality(this.state.offer.confidential))
      })
      .catch((error) => {
        this.setState({ loaded: true })
        console.log(error.response)
      });
    }
    else{
      const offerId = this.props.match.params.offerId;
      const company = this.props.imagesCompany.images_company
      this.setState({ offerId: offerId });
      const url = backend_url + 'company/offers/'+offerId;
      const params = {
        company_id: company.id,
      }
      axios.get(url, {params}, config)
      .then(response => {
        this.setState( { offer: response.data.data.offer, loaded: true });
        this.props.dispatch(setConfidentiality(this.state.offer.confidential))
      })
      .catch((error) => {
        this.setState({ loaded: true })
        console.log(error.response)
      });
    }
  }

  alreadyApplied() {
    const newOfferId = this.props.match.params.offerId;
    const newUrl = backend_url + '/company/find_offerapplicants/';
    const params = {
      offer_id: newOfferId,
      applicant_id: this.props.applicantSession.applicant.id
    }
    axios.get(newUrl, {params}, config)
    .then(response => {
      const res = response.data.messages === 'You can Apply to this offer'? true : false;
      this.setState( { showButton: res });
    })
    .catch((error) => {
      console.log(error.response);
    });
  }

  componentDidMount() {
    const company = this.props.imagesCompany.images_company;
    if (!company) {
      this.getCompany(); }
    else {
      this.getOffer();}

    if (this.props.applicantSession.applicant){ this.alreadyApplied();}
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.match.params.offerId !== this.props.match.params.offerId) {
      this.getCompany();
    }

    if(prevProps.applicantSession.applicant !== this.props.applicantSession.applicant) {
      if (this.props.applicantSession.applicant){ this.alreadyApplied();}
      else {this.setState( { showButton: true })}
    }
  }

  handleOpenRegister = () => {
    this.setState({ openModal: true, modalContent: <ApplicantRegister closeModal={this.handleCloseModal.bind(this)}/> })
  }

  getAxios() {
    const url = this.state.offer.origin === 'kc' ? backend_url + "/company/kc_postulations" : backend_url + "/api/v1/ats/ats_postulations"
    let body = {}
    if(this.state.offer.origin === 'kc'){
      body = {kc_postulation: { offer_id: this.state.hashedData.offerId ? this.state.hashedData.offerId : this.props.match.params.offerId, applicant_id: this.props.applicantSession.applicant.id }}
    } else {
      body = {ats_postulation: { offer_id: this.state.hashedData.offerId ? this.state.hashedData.offerId : this.props.match.params.offerId, applicant_id: this.props.applicantSession.applicant.id, email: this.props.applicantSession.applicant.email }}
    }
    const queryParameters = new URLSearchParams(window.location.search)
    body.origin= queryParameters.get("origin");
    axios.post(url, body, config)
    .then(response => {
      this.setState({ offerApply: false })
      if (response.status === 200) {
        const redirect_url = portal_url + `/?apikey=${response.data.data.jwt_applicant}`
        window.open(redirect_url, "_blank")
      }
    })
    .catch(error =>{
      console.log(error)
      this.setState({ error: true, errorText:  "No se logró enviar la postulación" });
   });
  }

  handleApplyOffer = () => {
    this.setState({ error: false })
    if(this.props.applicantSession.applicant) {
      this.setState({ showButton: false })
      this.getAxios();
      return;
    };
    const modalSize =  '400px';
    this.setState({ modalSize, openModal: true, modalContent: <ApplicantLogin handleForgot={this.handleForgot.bind(this)} register={this.handleOpenRegister.bind(this)} closeModal={this.handleCloseModal.bind(this)}/>});
  }

  handleCloseModal = () => {
    this.setState({ openModal: false })
  }

  handleForgot() {
    this.handleCloseModal()
    this.setState({forgot: true})
  }

  handleLogo= () => {
    if (this.props.notFound) return <img src={DefaultCompanyLogo} alt="" width={200} height={95}/>

    const company = this.props.imagesCompany.images_company;
    if (this.props.children === 'Home' || company) {
      if (this.props.children === 'Home') return <img src={DefaultCompanyLogo} alt="" width={200} height={95}/>
      const url = "/" + company.domain;
      return (
        <NavLink to={{pathname: url}}>
          {company.logo_url ? <img src={company.logo_url} alt=""  width={143} height={63}/> : <img src={DefaultCompanyLogo} alt="" width={200} height={95}/> }
        </NavLink>
      )
    }
    return <img src="" alt="" width={143} height={63}/>
  }


  render() {
    const company = this.props.imagesCompany.images_company;
    if (this.state.offer.state !== 'Activada') {
      return (
        <div className="container">
          <Breadcrumb
            offerCargo=''
            company={this.props.match.params.company}
          />
          { this.state.loaded ?
            <div className="notFound">
              <div className="notFoundMsg col-12 col-md-6">
                <p className="notFound1">Por ahora no contamos con esta oferta laboral</p>
                <p className="notFound2">Regresa en otro momento y tendremos novedades.</p>
                <p className="notFound3">Nos vemos!!</p>
              </div>
              <div className="notFoundImage col-12 col-md-6">
              <img src={NotFound} class="img-fluid" alt=""/>
              </div>
            </div>
          : null }
        </div>
      )
    }
    return (
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <Breadcrumb
              offerCargo={this.state.offer.name}
              company={company.domain}
              confidential={this.state.offer.confidential}
            />
            { this.state.error ? <Mensaje className={"red"} mensaje={this.state.errorText} /> : null }
            { this.state.offerApply || !this.props.applicantSession.applicant ?
              <>
                <OfferInfo
                  company={!!this.state.offer.confidential ? this.state.offer.fantasy_name : company.razon_social}
                  cargoName={this.state.offer.name}
                  region={this.state.offer.region}
                  commune={this.state.offer.commune}
                  teletrabajo={this.state.offer.teletrabajo}
                  description={this.state.offer.description}
                  demands={this.state.offer.demands}
                  benefit={this.state.offer.benefit}
                  modality={this.state.offer.modality}
                />
                <OfferFooter onClick={this.handleApplyOffer.bind(this)}
                  shareUrl={window.location.href}
                  showButton={this.state.showButton}
                  shareText={'Nueva oferta de empleo: Postula a '+ this.state.offer.name +' en '+ company.razon_social}
                  company={company.razon_social}/>
              </>
            : <ApplyOffer origin={this.state.offer.origin}/> }
          </div>
          {!!!this.state.offer.confidential  && 
            <div className="col-lg-4 my-4 border-start">
              { this.state.offer.id ?
                <OtherOffers
                  offerId={this.state.offer.id}
                  company_id={company.id}
                  subdomain={company.domain}
                  razon_social={company.razon_social}
                />
              : null }
            </div>
          }
        </div>
        <ModalTcn
          open={this.state.openModal}
          onClose={this.handleCloseModal}
          container={this.state.modalContent}
          footer={false}
          title={""}
        />
        <RecoverPasswordModal
          open={this.state.forgot}
          loading={this.state.loading}
          handleForgot={this.handleForgot.bind(this)}
        />
        <LoadingModal
          open={this.state.loading}
          marginTop="300px"
        />
      </div>
    )
  }
}

const OfferShow = connect(mapStateToProps)(OfferShowConnect);
export default OfferShow;
