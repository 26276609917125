import axios from 'axios';
import React from 'react';
import ModalTcn from '../../../common/modal';
import ApplicantLogin from './applicant_login';
import ApplicantRegister from './ApplicantRegister'
import { connect } from 'react-redux';
import DropdownApplicant from './DropdownApplicant';
import RecoverPasswordModal from './RecoverPasswordModal';
import LoadingModal from '../../../common/loadingModal';
import Cookies from 'js-cookie';
import { isMobile } from "react-device-detect";
import { applicantSession } from '../../../store/actions/login_applicant';
import { ValidateEmail } from '../../../assets/methods/validateEmail';
import LogoTalana from '../../../assets/images/talana-logo-black.svg';
import DefaultCover from '../../../assets/images/layout-1.jpeg';

const mapStateToProps = state => {
  return {
    applicantSession: state.applicantSession,
    imagesCompany: state.imagesCompany,
    notFound: state.notFound,
    confidentiality: state.companyConfidentiality.confidentiality
  }
}

class NavbarConnected extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      modalContent: null,
      forgot: false,
      forgotEmail: '',
      passEmail: false,
      emailError: false,
      errorMessage: '',
      loading: false,
      comṕanyLogoUrl: 'https://www.google.com/images/branding/googlelogo/2x/googlelogo_color_92x30dp.png'
    }
  }

  handleOpenLogin = () => {
    this.setState({ openModal: true, modalContent: <ApplicantLogin handleForgot={this.handleForgot.bind(this)} register={this.handleOpenRegister.bind(this)} closeModal={this.handleCloseModal.bind(this)}/> })
  }

  handleOpenRegister = () => {
    this.setState({ openModal: true, modalContent: <ApplicantRegister closeModal={this.handleCloseModal.bind(this)}/> })
  }

  handleCloseModal = () => {
    this.setState({ openModal: false })
  }

  loginCookie() {
    const login_cookie = Cookies.get('session_keyjobs')
    if (!login_cookie && this.props.applicantSession.applicant){
      this.props.dispatch(applicantSession(null))
    }
  }

  applicantDropdown = () => {
    this.loginCookie();
    if (!this.props.applicantSession.applicant) {
      return (
        <button onClick={this.handleOpenLogin} className='btn btn-sm btn-primary' >Iniciar sesión</button>
      )
    }
    else {
      return (
        <DropdownApplicant applicantName={this.props.applicantSession.applicant.nombres}/>
      )
    }
  }

  CompanyLogo = () => {
    if(this.props.notFound) return;
    if(this.props.confidentiality) return;
   
    const companyImages = this.props.imagesCompany.images_company;

    return (
      (companyImages && companyImages.logo_url ? companyImages.logo_url : null) ? 
        <img src={companyImages.logo_url} alt={companyImages && companyImages.razon_social ? companyImages.razon_social : null} className="m-auto" width="fit-content" height={34}/>
      : null)
  }

  handleCover = () => {
    if (this.props.notFound) return <img src={DefaultCover} alt="" className="img-cover"/>
    const company = this.props.imagesCompany.images_company;
    if (this.props.children === 'Home' || company) {
      if (this.props.children === 'Home') return <img src={DefaultCover} alt="" className="img-cover"/>
      if(this.props.confidentiality) return <img src={DefaultCover} alt="" className="img-cover"/>
      if (company.top_url) return <img src={company.top_url} alt="" className="img-cover" />
      return <img src={DefaultCover} alt="" className="img-cover"/>
    }
    return <img src={DefaultCover} alt="" className="img-cover"/>
  }

  handleForgot() {
    this.handleCloseModal()
    this.setState({forgot: true})
  }

  handleRecoveryMail(event) {
    this.setState({forgotEmail: event.target.value})
  }

  handleSendPassword() {
    this.setState({emailError: false, passEmail: false})
    const email = this.state.forgotEmail;
    if (!email || !ValidateEmail(email)) {
      this.setState({emailError: true, errorMessage: 'Debes ingresar un email existente'})
      return
    }
    this.setState({loading: true})
    const kc_url = process.env.REACT_APP_KEYCLOUD_HOST;
    axios.post(kc_url + "/api/v2/applicant/forgot_password", {email: email})
    .then(response => {
      this.setState({passEmail: true, loading: false})
    })
    .catch(error => {
      if (error.response.status === 404) {
        this.setState({emailError: true, loading: false, errorMessage: 'Este email no se encuentra registrado'})
      }
      console.log(error.response)
    })
  }

  onCloseRecovery() {
    this.setState({forgot: false, emailError: false, passEmail: false, forgotEmail: ''})
  }

  render() {
    return (
      <>
        <nav className="navbar navbar-default">
          <div className="container-fluid px-4">
            <div className="navbar-header d-flex">
              {(this.props.notFound) && (<a href="https://web.talana.com/" target="_blank"><img src={LogoTalana} alt="talana" width="fit-content" height={36} style={{maxWidth: "min-content"}}/></a>)}
              {/* Company dinamic logo */}
              {this.CompanyLogo()}
            </div>
            <div>
              { this.applicantDropdown() }
            </div>
          </div>
        </nav>
        {!isMobile ? <div className="mb-4">
          { this.handleCover() }
        </div> : null}
        <ModalTcn
          open={this.state.openModal}
          onClose={this.handleCloseModal}
          container={this.state.modalContent}
          footer={false}
          title={""}
        />
        <RecoverPasswordModal
          open={this.state.forgot}
          handleRecoveryMail={this.handleRecoveryMail.bind(this)}
          onClose={this.onCloseRecovery.bind(this)}
          sendPassword={this.handleSendPassword.bind(this)}
          emailPass={this.state.passEmail}
          error={this.state.emailError}
          loading={this.state.loading}
          handleForgot={this.handleForgot.bind(this)}
          errorMessage={this.state.errorMessage}
        />
        <LoadingModal
          open={this.state.loading}
          marginTop="300px"
        />
      </>
    )
  }
};


const Navbar = connect(mapStateToProps)(NavbarConnected);
export default Navbar;
