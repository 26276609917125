import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <div>
    <style>
      @import url('https://fonts.googleapis.com/css?family=Roboto:400,500&display=swap');
    </style>
    <App />
  </div>,
  document.getElementById('root')
);

serviceWorker.unregister();

