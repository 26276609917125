import React from 'react';
import Navbar from '../components/navbar';

class LayoutForShow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    return (
      <>
        <Navbar/>
        <div className={"col-md-12"}>
          {this.props.children}
        </div>
      </>
    )
  }
}

export default LayoutForShow;
