import React, {Component} from 'react';

class Accept extends Component {
  constructor(props) {
    super(props);
    this.state = {checked: false}
  }

  handlerChange(){
    this.setState({checked: !this.state.checked})
  }

  render() {
    return(
      <div className='checkbox'>
        <label className='ter_y_con' style={{fontSize: '14px'}}>
          <input type='checkbox' onChange={this.handlerChange.bind(this)} checked={this.state.checked} className='me-2' />
          He leído y acepto los <a rel="noopener noreferrer" target="_blank" href="https://storage.googleapis.com/talana-seleccion-static/T%26C_Seleccion-postulantes.pdf">Términos y Condiciones</a> y las <a rel="noopener noreferrer" target="_blank" href="https://storage.googleapis.com/talana-seleccion-static/Pol%C3%ADtica_de_Privacidad_Selecci%C3%B3n_Talana.pdf">Políticas de privacidad</a> de Talana
        </label>
        <div className='d-flex mt-2'>
          <button id="registerBtn" type="button" className="btn btn-primary w-auto m-auto px-3 rounded-5" disabled={!this.state.checked} onClick={this.props.onClick}>
            Registrarme
          </button>
        </div>
      </div>
    )
  }
}

export default Accept;
