import { IMAGES_COMPANY, NOT_FOUND } from '../constants/index';

export const imagesCompany = (state = {}, {type, images_company}) => {
  switch (type) {
    case IMAGES_COMPANY:
      return {
        images_company: images_company
      }
    default:
      return state;
  }
}

export const notFound = (state = false, {type}) => {
  switch (type) {
    case NOT_FOUND:
      return {
        not_found: true
      }
    default:
      return state;
  }
}
