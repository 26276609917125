import React from 'react';
import Pagination from '../../../common/pagination';
import { SyncLoader } from 'react-spinners';

const PageSpinner = (props) => {
  return (
    <SyncLoader
      color={'#123abc'}
    />
  )
};

class PaginationBloque extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  infoPagination() {
    const resultsCount = this.props.resultsCount;
    const pagesCount = this.props.pagesCount;
    const initialIndex = this.props.currentPage * 5 + 1
    return (
      <p className="roboto regular" style={{fontSize:"13px"}}>
        {(resultsCount && pagesCount) ?
          (`${Math.min(resultsCount, initialIndex)} - ${Math.min(Math.min(resultsCount, 5) + initialIndex - 1, resultsCount)} de ${resultsCount} resultados`)
        : `0 - 0 de 0 resultados`}
      </p>
    )
  }

  render() {
    return (
      <div className="d-flex flex-column">
        <div className="w-100" style={{display: "inline-block", float: "left", }}>
          {this.infoPagination()}
        </div>
        <div className="row mb-4">
          <div className="col" style={{display: "flex", justifyContent: "center"}}>
            <Pagination
              pagesHandler={this.props.pagesHandler}
              pagesCount={this.props.pagesCount}
              forcePage={this.props.currentPage}
              previousLabel={"<"}
              nextLabel={">"}
              activeClassName={"active page-active page-active-green"}
            />
          </div>
        </div>
          { this.props.spinnerPage ? 
            <div className="d-flex justify-content-center">
              <PageSpinner />
            </div> 
          : null }
      </div>
    )
  }
}

export default PaginationBloque;
