import React from 'react';
import Select from 'react-select';

const formatNoOptonsMessage = (value) => {
  return 'No hay opciones'
}

const SelectComponent = (props) => {

  const colourStyles = {
    control: (styles, state) => ({
      ...styles,
      border: 'none',
      borderRadius: '8px',
      minHeight: '36px',
      transition: 'all .2s ease-out',
      outline: (state.isFocused) ? '2px solid var(--t-secondary) !important' : '1px solid rgba(10, 7, 22, 0.24)',
      "&:hover": {
        outline: '1px solid rgba(39, 39, 63, 1)'
      },
    }),
    menu: provided => ({ 
      ...provided, 
      borderRadius: '8px',
      padding: '3px 6px',
      zIndex: 99,
    }),
    option: (styles, state) => ({
      borderRadius: '4px',
      padding: '6px 8px',
      backgroundColor: state.isSelected ? "rgba(133, 103, 255, 0.16)" : null,
      cursor: 'pointer',
      "&:hover": {
        backgroundColor: 'rgba(133, 103, 255, .08)'
      },
    }),
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: '#C8B9FD',
        borderRadius: '1rem',
        color: 'rgba(40, 34, 60, 1)',
        fontWeight: '500',
        noOptionsMessage: 'No hay opciones',
      };
    },
  };

  const colourStylesRed = {
    control: styles => ({...styles, borderColor: 'red'}),
  };

  return(
    <div>
      {props.title.includes('*')? <label className={props.classSelect}>{props.title.substring(0, props.title.length - 1)} <span className='red'>*</span></label> : <label className={props.classSelect}>{props.title}</label>}
      <Select
        isMulti={props.multi}
        onChange={props.onChange}
        options={props.options}
        value={props.value}
        placeholder={props.placeholder ? props.placeholder : "Seleccione..."}
        styles={props.isEmpty ? colourStylesRed : colourStyles}
        noOptionsMessage={formatNoOptonsMessage}
      />
      <span className="selector-note">{props.note}</span>
    </div>
  );
}

export default SelectComponent;
